import React, { useEffect, useState } from "react";
import styles from "./report.module.scss";

import t from "../../_config/constants";
import { getStatistics } from "../../_services/statistics.service";

import {
  monthsOptions,
  yearsOptions,
  dateOptions,
  periodOptions,
  numberOptions,
} from "./../selfAssignedReport/utils";
import { useSelector } from "react-redux";
import Selector from "../selector";
import Checkbox from "../checkbox";
const DynamicLinksReport = () => {
  const [report, setReport] = useState({});
  const user = useSelector((state) => state?.auth?.user);
  const [selectedRange, setSelectedRange] = useState({
    from: {
      date: 1,
      month: new Date().getMonth(),
      year: new Date().getFullYear(),
    },
    to: {
      date: new Date().getDate(),
      month: new Date().getMonth(),
      year: new Date().getFullYear(),
    },
  });
  const [fromTime, setFromTime] = useState({
    number: 4,
    period: 7 * 24 * 60 * 60 * 1000,
  });

  const [previousToDate, setPreviousToDate] = useState(true);
  useEffect(() => {
    const fetchStatistics = async (
      regionId,
      previousToDate,
      range,
      fromTime
    ) => {
      const date = new Date();
      const response = await getStatistics({
        mode: "get-dynamic-links-statistics",
        filter: {
          regionId: regionId,
          filterQuery: {
            createdAt: previousToDate
              ? {
                  $gte: date.getTime() - fromTime.number * fromTime.period,
                }
              : {
                  $gte: new Date(
                    range.from.year,
                    range.from.month,
                    range.from.date
                  ).getTime(),
                  $lte: new Date(
                    range.to.year,
                    range.to.month,
                    range.to.date + 1
                  ).getTime(),
                },
          },
        },
      });
      setReport(response);
    };

    if (user) {
      fetchStatistics(user.regionId, previousToDate, selectedRange, fromTime);
    }
  }, [user, selectedRange, fromTime, previousToDate]);

  const getSelectedValue = (options, value) => {
    const selectedData = [];
    options &&
      options.forEach((obj) => {
        if (obj.value === value) {
          selectedData.push(obj);
        }
      });
    return selectedData;
  };
  return (
    <div className={styles.wrapper}>
      <h3> {t.reports.dynamic_links_stats}</h3>
      <div className={styles.filterOptions}>
        <div className={styles.selectDate}>
          <div className={styles.checkbox}>
            <Checkbox
              customClass="checkbox"
              label={"Previous"}
              onChange={() => {
                setPreviousToDate((prev) => !prev);
              }}
              checked={previousToDate}
            />
          </div>
          <div
            className={`${styles.selectYear} ${
              previousToDate ? "" : styles.selectDisabled
            }`}
          >
            <Selector
              label=""
              disabled={!previousToDate}
              multiselect={false}
              selectClassname="selectSecondaryWhiteBg"
              selectedOption={getSelectedValue(numberOptions, fromTime.number)}
              handleChangeSelect={(e) =>
                setFromTime((prev) => ({ ...prev, number: e.value }))
              }
              options={numberOptions}
            />
          </div>
          <div
            className={`${styles.selectMonth} ${
              previousToDate ? "" : styles.selectDisabled
            }`}
          >
            <Selector
              label=""
              disabled={!previousToDate}
              multiselect={false}
              selectClassname="selectSecondaryWhiteBg"
              selectedOption={getSelectedValue(periodOptions, fromTime.period)}
              handleChangeSelect={(e) =>
                setFromTime((prev) => ({ ...prev, period: e.value }))
              }
              options={periodOptions}
            />
          </div>
        </div>
        <div className={styles.selectDate}>
          <div className={styles.checkbox}>
            <Checkbox
              customClass="checkbox"
              label={"Date Range"}
              onChange={() => {
                setPreviousToDate((prev) => !prev);
              }}
              checked={!previousToDate}
            />
          </div>
          <div
            className={`${styles.selectYear} ${
              previousToDate ? styles.selectDisabled : ""
            }`}
          >
            <Selector
              label=""
              disabled={previousToDate}
              multiselect={false}
              selectClassname="selectSecondaryWhiteBg"
              selectedOption={getSelectedValue(
                dateOptions,
                selectedRange.from.date
              )}
              handleChangeSelect={(e) =>
                setSelectedRange((prev) => ({
                  ...prev,
                  from: { ...prev.from, date: e.value },
                }))
              }
              options={dateOptions}
            />
          </div>
          <div
            className={`${styles.selectMonth} ${
              previousToDate ? styles.selectDisabled : ""
            }`}
          >
            <Selector
              label=""
              disabled={previousToDate}
              multiselect={false}
              selectClassname="selectSecondaryWhiteBg"
              selectedOption={getSelectedValue(
                monthsOptions,
                selectedRange.from.month
              )}
              handleChangeSelect={(e) =>
                setSelectedRange((prev) => ({
                  ...prev,
                  from: { ...prev.from, month: e.value },
                }))
              }
              options={monthsOptions}
            />
          </div>
          <div
            className={`${styles.selectYear} ${
              previousToDate ? styles.selectDisabled : ""
            }`}
          >
            <Selector
              label=""
              disabled={previousToDate}
              multiselect={false}
              selectClassname="selectSecondaryWhiteBg"
              selectedOption={getSelectedValue(
                yearsOptions,
                selectedRange.from.year
              )}
              handleChangeSelect={(e) =>
                setSelectedRange((prev) => ({
                  ...prev,
                  from: { ...prev.from, year: e.value },
                }))
              }
              options={yearsOptions}
            />
          </div>
          -
          <div
            className={`${styles.selectYear} ${
              previousToDate ? styles.selectDisabled : ""
            }`}
          >
            <Selector
              label=""
              disabled={previousToDate}
              multiselect={false}
              selectClassname="selectSecondaryWhiteBg"
              selectedOption={getSelectedValue(
                dateOptions,
                selectedRange.to.date
              )}
              handleChangeSelect={(e) =>
                setSelectedRange((prev) => ({
                  ...prev,
                  to: { ...prev.to, date: e.value },
                }))
              }
              options={dateOptions}
            />
          </div>
          <div
            className={`${styles.selectMonth} ${
              previousToDate ? styles.selectDisabled : ""
            }`}
          >
            <Selector
              label=""
              disabled={previousToDate}
              multiselect={false}
              selectClassname="selectSecondaryWhiteBg"
              selectedOption={getSelectedValue(
                monthsOptions,
                selectedRange.to.month
              )}
              handleChangeSelect={(e) =>
                setSelectedRange((prev) => ({
                  ...prev,
                  to: { ...prev.to, month: e.value },
                }))
              }
              options={monthsOptions}
            />
          </div>
          <div
            className={`${styles.selectYear} ${
              previousToDate ? styles.selectDisabled : ""
            }`}
          >
            <Selector
              label=""
              disabled={previousToDate}
              multiselect={false}
              selectClassname="selectSecondaryWhiteBg"
              selectedOption={getSelectedValue(
                yearsOptions,
                selectedRange.to.year
              )}
              handleChangeSelect={(e) =>
                setSelectedRange((prev) => ({
                  ...prev,
                  to: { ...prev.to, year: e.value },
                }))
              }
              options={yearsOptions}
            />
          </div>
        </div>
      </div>
      <div className={styles.table}>
        <table>
          <thead>
            <tr>
              <th></th>
              <th>All Devices</th>
              <th>Android</th>
              <th>IOS</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Number of Sent Links</td>
              <td className={styles.firstTd}>{report.total}</td>
              <td className={styles.firstTd}>/</td>
              <td className={styles.firstTd}>/</td>
            </tr>
            {Object.keys(report?.stats || {}).map((key) => (
              <tr key={key}>
                <td>{report.stats[key]?.event}</td>
                <td>{report.stats[key]?.all}</td>
                <td>{report.stats[key]?.android}</td>
                <td>
                  {key == "APP_INSTALL"
                    ? "Blocked by Apple"
                    : report.stats[key]?.ios}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DynamicLinksReport;
