import React, { useState } from "react";
import styles from "./reports.module.scss";
import UserAppInteractionsReport from "../../_components/userAppInteractionsReport";
import t from "../../_config/constants";
import SelfAssignedReport from "../../_components/selfAssignedReport";
import DynamicLinksReport from "../../_components/dynamicLinksReport";
import SubregionStatisticsReport from "../../_components/subregionStatisticsReport";
const Reports = () => {
  const [activeTab, setActiveTab] = useState("interactions");

  return (
    <div className={styles.wrapper}>
      <div className={styles.contentWrapper}>
        <div className={styles.sideMenu}>
          <div className={styles.header}>{t.reports.reports_and_analytics}</div>
          <div
            className={
              activeTab === "interactions" ? styles.activeTab : styles.listItem
            }
            onClick={() => {
              setActiveTab("interactions");
            }}
          >
            {t.reports.user_app_usage_behavior}
          </div>
          <div
            className={
              activeTab === "self-assigned" ? styles.activeTab : styles.listItem
            }
            onClick={() => {
              setActiveTab("self-assigned");
            }}
          >
            {t.reports.self_assigned_containers}
          </div>
          <div
            className={
              activeTab === "dynamic-links" ? styles.activeTab : styles.listItem
            }
            onClick={() => {
              setActiveTab("dynamic-links");
            }}
          >
            {t.reports.dynamic_links_stats}
          </div>
          <div
            className={
              activeTab === "subregion-stats"
                ? styles.activeTab
                : styles.listItem
            }
            onClick={() => {
              setActiveTab("subregion-stats");
            }}
          >
            {t.reports.subregion_stats}
          </div>
        </div>
        <div className={styles.content}>
          {activeTab === "interactions" && <UserAppInteractionsReport />}
          {activeTab === "self-assigned" && <SelfAssignedReport />}
          {activeTab === "dynamic-links" && <DynamicLinksReport />}
          {activeTab === "subregion-stats" && <SubregionStatisticsReport />}
        </div>
      </div>
    </div>
  );
};
export default Reports;
