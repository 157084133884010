import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import styles from "./line.module.scss";

const LineChart = React.memo(({ title, arrayForLineChart }) => {
  var [lineLabels, setLineLabels] = useState([]);
  var [lineData, setLineData] = useState([]);

  useEffect(() => {
    const valueArr = [];
    for (var i = 0; i < arrayForLineChart.length; i++) {
      valueArr.push(arrayForLineChart[i].value);
    }
    setLineData(valueArr);
  }, [arrayForLineChart]);

  lineLabels = [...new Set(arrayForLineChart.map((obj) => obj.labels))];

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        grid: {
          lineWidth: 0.5,
          drawBorder: false,
        },
        beginAtZero: true,
        ticks: {
          callback: function (value, index, values) {
            return value;
          },
          color: "#43425D",
          font: 12,
          padding: 20,
          precision: 0,
        },
      },
      x: {
        grid: {
          lineWidth: 0.5,
          drawBorder: false,
        },
        ticks: {
          color: "#43425D",
          font: 12,
          padding: 20,
        },
      },
    },
  };

  const data = {
    labels: lineLabels,
    datasets: [
      {
        minHeight: 50,
        label: "",
        data: lineData,
        fill: false,
        lineTension: 0.4,
        backgroundColor: "#2B5B6C",
        pointBackgroundColor: "#fff",
        pointBorderWidth: "2",
        borderColor: "#2B5B6C",
      },
    ],
  };

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.title}>{title}</div>
        <Line
          className={styles.bigChart}
          height={320}
          width={500}
          data={data}
          options={options}
        />
      </div>
    </>
  );
});

export default LineChart;
