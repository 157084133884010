import React from "react";
import styles from "./container.module.scss";

const ContainerBox = ({ name, iconURL, amount }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <p>{name}</p>
        {iconURL && (
          <div className={styles.imgContainer}>
            <img src={iconURL} />
          </div>
        )}
      </div>
      <div className={styles.content}>{amount}</div>
    </div>
  );
};

export default ContainerBox;
