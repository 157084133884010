import React from "react";
import styles from "./containers.module.scss";
import ContainerBox from "./containerBox";

const ContainerStats = ({ data = {} }) => {
  return (
    <div className={styles.wrapper}>
      <ContainerBox name={"Containers in use"} amount={data.inUse} />
      <ContainerBox
        name={"Containers in inventory"}
        amount={data.inStoreInventory}
      />

      {data.containerTypes?.map((type) => {
        return (
          <ContainerBox
            key={type?.name}
            name={type.name}
            amount={type.amount}
            iconURL={type.iconURL}
          />
        );
      })}
    </div>
  );
};

export default ContainerStats;
