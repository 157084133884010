import React, { useEffect, useState } from "react";
import styles from "./barchart.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { getStatistics } from "../../../../_services/statistics.service";
import Selector from "../../../selector";
import Loading from "../../../loading";
import BarChart from "../../../charts/containersBarChart";
import { mainActions } from "../../../../_store/actions/main.actions";
import { makeSelectMap } from "../../../../_utils/utils";
import Datepicker from "../../../newDatepicker";

const ReturnedBarChart = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();

  const [loadingData, setLoadingData] = useState(true);
  const [locationOptions, setLocationOptions] = useState([]);
  const [date, setDate] = useState(new Date());
  const [getTodaysStats, setGetTodaysStats] = useState(true);
  const [statisticsFilter, setStatisticsFilter] = useState({
    locationId: "",
    containerTypeId: "",
  });
  const [dataForBarChart, setDataForBarChart] = useState([]);

  useEffect(() => {
    const getData = async (subregionId, filter, date) => {
      setLoadingData(true);
      filter.subregionId = subregionId;
      filter.period = "hourly";
      filter.year = date.getFullYear();
      filter.month = date.getMonth();
      filter.day = date.getDate();
      const isDateCurrentDay =
        date.getFullYear() === new Date().getFullYear() &&
        date.getMonth() === new Date().getMonth() &&
        date.getDate() === new Date().getDate();

      setGetTodaysStats(isDateCurrentDay);

      const response = await getStatistics({
        mode: "get-subregion-return-statistics",
        filter: filter,
      });
      if (response) {
        setDataForBarChart(response.array || {});
      }
      setLoadingData(false);
    };
    if (
      store.auth &&
      store.auth.user &&
      store.auth.user.subregionId &&
      statisticsFilter.locationId &&
      date
    ) {
      getData(store.auth.user.subregionId, statisticsFilter, date);
    } else {
      setLoadingData(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statisticsFilter, date]);
  useEffect(() => {
    const getLocations = async (id) => {
      let filter = [];
      if (id) filter.push({ subregionId: id });
      let data = await dispatch(
        mainActions.run("data", "location", "all", { filter })
      );
      if (data) {
        let arr = makeSelectMap(data);
        let filterOptions = [...arr];
        if (arr.length == 0)
          filterOptions.unshift({ label: "All Bins", value: "" });
        setLocationOptions(filterOptions);
      }
    };

    if (store.auth && store.auth.user && store?.auth?.user?.subregionId) {
      getLocations(store?.auth?.user?.subregionId);
    }
  }, [store.auth && store.auth.user]);

  return (
    <div className={styles.wrapper}>
      <h3>Smart Return Bins Status</h3>
      <div className={styles.header}>
        <div className={styles.select}>
          <Selector
            label=""
            placeholder="Select Bin"
            multiselect={false}
            selectClassname="selectGray"
            options={locationOptions}
            disabled={loadingData}
            handleChangeSelect={(e) =>
              setStatisticsFilter((prev) => ({
                ...prev,
                locationId: e.value,
              }))
            }
          />
        </div>

        <div className={styles.row}>
          <div className={styles.selectDate}>
            {" "}
            <div className={styles.datepicker}>
              <Datepicker
                label={"Date"}
                minDate={new Date(2023, 9, 1).getTime()}
                maxDate={new Date().getTime()}
                selected={date}
                onDatePickerChange={(val) => setDate(val)}
                name="date"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.chartWrapper}>
        {loadingData && (
          <div className={styles.loading}>
            <Loading width={50} height={50} />
          </div>
        )}

        {!loadingData && dataForBarChart && (
          <div className={styles.bigChartSection}>
            {!statisticsFilter.locationId && (
              <div className={styles.selectLocationMsg}>
                Select Bin To See Stats
              </div>
            )}
            <BarChart
              dataForBarChart={dataForBarChart}
              getTodaysStats={getTodaysStats}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ReturnedBarChart;
