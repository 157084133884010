import React, { useEffect, useState } from "react";
import styles from "./subregionnavbar.module.scss";
import userDefault from "../../_assets/icons/user.svg";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const SubregionNavbar = () => {
  const location = useLocation();
  const store = useSelector((state) => state);
  const [header, setHeader] = useState("");
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://code.tidio.co/pip2jkpm0bdqc2gg9g10sowy0erxkb61.js";
    script.async = true;
    script.id = "external-script";
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  useEffect(() => {
    let header = "";
    switch (location.pathname) {
      case "/containers-stats":
        header = "Containers and SRB's";
        break;
      case "/impact":
        header = "Impact";
        break;
      case "/container-usage":
        header = "Usage";
        break;
      default:
        header = "Containers and SRB's";
    }
    setHeader(header);
  }, [location]);

  return (
    <nav className={styles.subregionNavbar}>
      <div className={styles.header}>
        <h3>{header}</h3>
      </div>
      <div className={styles.content}>
        <div className={styles.border} />
        <div className={styles.userInfo}>
          <span>{store?.auth?.user?.fullName}</span>
          <img src={userDefault} />
        </div>
      </div>
    </nav>
  );
};

export default SubregionNavbar;
