import React, { useEffect, useState } from "react";
import styles from "./restaurantSelect.module.scss";
import Selector from "../../../../../selector";
import Button from "../../../../../button";
import { useDispatch, useSelector } from "react-redux";
import { mainActions } from "../../../../../../_store/actions/main.actions";
import { makeSelectMap } from "../../../../../../_utils/utils";
import toastService from "../../../../../../_services/toast.service";
import t from "../../../../../../_config/constants";
import moment from "moment";
const RestaurantSelect = ({ containers, handleClose }) => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const [locations, setLocations] = useState([]);
  const [locationId, setLocationId] = useState("");
  const [allLocations, setAllLocations] = useState([]);

  const getLocations = async (id) => {
    const filter = [{ isDraft: { $ne: true } }];
    if (id && id !== "*") filter.push({ regionId: id });
    let data = await dispatch(
      mainActions.run("data", "location", "all", { filter })
    );
    let arr = makeSelectMap(data);
    setAllLocations(data);
    setLocations(arr);
  };

  useEffect(() => {
    if (containers && store?.auth?.user?.regionId)
      getLocations(store.auth.user.regionId);
  }, [store.auth && store.auth.user]);
  const handleSelect = (e) => {
    setLocationId(e.value);
  };
  const getSelectedValue = (options, value) => {
    const selectedData = [];
    options &&
      options.forEach((obj) => {
        if (obj.value === value) {
          selectedData.push(obj);
        }
      });
    return selectedData;
  };
  const calculateDaysToReturn = (container) => {
    const today = moment(new Date().getTime()).startOf("day");
    const duedate = moment(container.days_to_return).startOf("day");
    return today.diff(duedate, "days");
  };
  const returnContainers = async (e) => {
    e.preventDefault();
    let ids = containers.map((item) => item._id);
    let user = await dispatch(
      mainActions.run("profiles", "user", "id", containers[0].user_id)
    );
    if (!user) {
      user = await dispatch(
        mainActions.run("profiles", "unregistered", "id", containers[0].user_id)
      );
    }
    if (user) {
      for (let i = 0; i < containers.length; i++) {
        let isDuedate = calculateDaysToReturn(containers[i]);
        user.inPossession -= 1;
        if (isDuedate == 0) user.dueDate -= 1;
        if (isDuedate > 0) user.overdue -= 1;
      }
    }
    const location = allLocations.find(
      (location) => location._id === locationId
    );
    let response;
    if (location.isRestaurant && location.isCleaning) {
      response = await dispatch(
        mainActions.run("data", "container", "updateMulti", {
          ids: ids,
          state: "restaurant",
          restaurant_id: location.restaurant_id,
          locationName: location.name,
        })
      );
    } else {
      response = await dispatch(
        mainActions.run("data", "container", "updateMulti", {
          ids: ids,
          state: "location",
          location_id: locationId,
          locationName: location.name,
        })
      );
    }

    if (response) {
      toastService.show("success", t.user.text_successfully_returned);
      await dispatch(
        mainActions.run("profiles", user.type, "update", user, {
          _id: user._id,
        })
      );
    }
    containers = containers.map((c) => (c.state = "location"));
    handleClose();
  };
  return (
    <div className={styles.wrapper}>
      <p>
        {t.button.text_return}{" "}
        {containers.length > 1 ? "Containers" : "Container"}{" "}
      </p>
      <Selector
        options={locations}
        multiselect={false}
        handleChangeSelect={(e) => handleSelect(e)}
        selectedOption={
          locationId ? getSelectedValue(locations, locationId) : null
        }
        selectClassname="selectPrimary"
        placeholder={t.user.text_select_restaurant}
      />
      <Button
        btnClass="btnNormalCasal"
        label={t.button.text_return}
        type="btn"
        onClick={returnContainers}
        disabled={!locationId}
      />
    </div>
  );
};
export default RestaurantSelect;
