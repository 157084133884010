import React, { useState } from "react";
import Button from "./../../../button";
import t from "./../../../../_config/constants";
import { exportToCSV } from "../../../../_utils/exportToCSV";
import moment from "moment";
import { getStatistics } from "../../../../_services/statistics.service";
import { isSameMonthAndYear } from "../../../../_utils/mapTransactions";
const DownloadData = React.memo(
  ({
    disabled,
    regionId,
    selectedYear,
    selectedRestaurant,
    restaurants,
    selectedMonth,
    selectedMonthly,
  }) => {
    const [loadingCSV, setLoadingCSV] = useState(false);
    const getData = async () => {
      setLoadingCSV(true);
      document.body.style.cursor = "wait";
      let restaurantIds = selectedRestaurant
        ? [selectedRestaurant]
        : restaurants.map((item) => item._id);
      let result;
      let fileName = "Store Statistics - ";
      if (selectedRestaurant) {
        fileName =
          restaurants.find(
            (restaurant) => restaurant._id + "" === selectedRestaurant
          )?.name + " Statistics - ";
      }
      if (selectedYear && selectedMonthly) {
        result = await getStatistics({
          mode: "get-monthly",
          filter: { restaurantIds, year: selectedYear, regionId },
        });
        fileName += selectedYear;
      } else {
        if (
          selectedMonth &&
          isSameMonthAndYear(selectedMonth, new Date().getTime())
        ) {
          result = await getStatistics({
            mode: "get-this-month",
            filter: {
              firstDay: new Date(
                selectedMonth.getFullYear(),
                selectedMonth.getMonth(),
                1
              ).getTime(),
              lastDay: new Date().getTime(),
              restaurantIds,
              regionId,
            },
          });
        } else {
          const firstDay = new Date(
            Date.UTC(
              selectedMonth.getFullYear(),
              selectedMonth.getMonth() + 1,
              1
            )
          ).getTime();
          const lastDay = new Date(
            Date.UTC(
              selectedMonth.getFullYear(),
              selectedMonth.getMonth() + 2,
              0
            )
          ).getTime();
          result = await getStatistics({
            mode: "get-daily",
            filter: { firstDay, lastDay, restaurantIds, regionId },
          });
        }
        fileName += moment(selectedMonth.getTime()).format("MMMM YYYY");
      }
      result.sort(function (a, b) {
        return a.Region > b.Region;
      });
      exportToCSV(result, fileName);
      setLoadingCSV(false);
      document.body.style.cursor = "auto";
    };
    const handleDownload = () => {
      getData();
    };

    return (
      <Button
        disabled={disabled || loadingCSV}
        label={t.restaurant.text_download_statistics}
        btnClass="btnWhiteBackground"
        onClick={handleDownload}
      />
    );
  }
);

export default DownloadData;
