import React, { useEffect, useState } from "react";
import Button from "../../../button";
import moment from "moment";
import { useSelector } from "react-redux";
import Datepicker from "../../../newDatepicker";
import styles from "./storeCheckoutLog.module.scss";
import Loading from "../../../loading";
import t from "../../../../_config/constants";
import arrowUp from "../../../../_assets/icons/arrowUp.svg";
import arrowDown from "../../../../_assets/icons/arrowDown.svg";
import upDownArrow from "../../../../_assets/icons/upDownArrow.svg";
import useSortableData from "../../../../_utils/useSortableData";
import { getStatistics } from "../../../../_services/statistics.service";

const StoresCheckoutLog = ({ restaurantId }) => {
  const [loading, setLoading] = useState(true);
  const [logs, setLogs] = useState([]);
  const store = useSelector((state) => state);
  const [date, setDate] = useState(new Date());

  const { handleSort, sortConfig } = useSortableData(logs);
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return upDownArrow;
    }
    return sortConfig.key === name
      ? sortConfig.direction === "ascending"
        ? arrowUp
        : arrowDown
      : upDownArrow;
  };

  useEffect(() => {
    const getRestaurantLogs = async (
      regionId,
      subregionId = "",
      date,
      sortConfig,
      restaurantId
    ) => {
      setLoading(true);
      let fromDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      ).getTime();

      const filter = {};
      const dateTo = new Date(fromDate).setDate(
        new Date(fromDate).getDate() + 1
      );
      filter.timestamp = { $gte: fromDate, $lte: dateTo };

      const response = await getStatistics({
        mode: "get-stores-activity-feed",
        filter: {
          regionId,
          subregionId,
          filter,
          sortConfig,
          restaurantId,
        },
      });
      setLogs(response);
      setLoading(false);
    };
    if (store?.auth?.user?.regionId) {
      getRestaurantLogs(
        store.auth.user.regionId,
        store.auth.user.subregionId,
        date,
        sortConfig,
        restaurantId
      );
    }
  }, [store.auth.user.regionId, date, sortConfig, restaurantId]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.datepicker}>
        <Datepicker
          minDate={new Date(2021, 5, 1).getTime()}
          maxDate={new Date().getTime()}
          selected={date}
          onDatePickerChange={(val) => setDate(val)}
          name="date"
          placeholder="mm/dd/yyyy"
        />
      </div>

      <div className={styles.table}>
        <table>
          <thead>
            <tr className={styles.topBar}>
              <th colSpan="6">Activity Feed</th>
            </tr>
            <tr>
              <th onClick={() => (loading ? null : handleSort("date"))}>
                {t.common.text_date}
                <Button
                  btnClass="btnBack"
                  iconLeft={getClassNamesFor("date")}
                />
              </th>
              <th
                onClick={() => (loading ? null : handleSort("containerType"))}
              >
                {t.restaurant.text_th_container_type}
                <Button
                  btnClass="btnBack"
                  iconLeft={getClassNamesFor("containerType")}
                />
              </th>
              <th
                onClick={() =>
                  loading ? null : handleSort("uniqueContainerId")
                }
              >
                {t.restaurant.text_th_unique_container_id}

                <Button
                  btnClass="btnBack"
                  iconLeft={getClassNamesFor("uniqueContainerId")}
                />
              </th>
              <th onClick={() => (loading ? null : handleSort("userName"))}>
                {t.user.text_member}
                <Button
                  btnClass="btnBack"
                  iconLeft={getClassNamesFor("userName")}
                />
              </th>
              <th onClick={() => (loading ? null : handleSort("storeName"))}>
                Store
                <Button
                  btnClass="btnBack"
                  iconLeft={getClassNamesFor("storeName")}
                />
              </th>
              <th onClick={() => (loading ? null : handleSort("activity"))}>
                {t.restaurant.text_th_activity}
                <Button
                  btnClass="btnBack"
                  iconLeft={getClassNamesFor("activity")}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {!loading &&
              logs &&
              logs.length > 0 &&
              logs.map((item, ind) => (
                <tr key={ind}>
                  <td>
                    {moment(
                      item.date ? item.date : new Date().getTime()
                    ).format("MMM D, YYYY HH:mm")}
                  </td>
                  <td>
                    <span>{item.containerType}</span>
                  </td>
                  <td>
                    <span>{item.uniqueContainerId || ""}</span>
                  </td>

                  <td>
                    <span>{item.userName || "Unregistered User"}</span>
                  </td>
                  <td>
                    <span>{item.storeName || "N/A"}</span>
                  </td>
                  <td>
                    <span>{item.activity}</span>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {!loading && logs.length === 0 && (
          <p className={styles.noData}>No Activity Found</p>
        )}
        {loading && (
          <div className={styles.loading}>
            <Loading width={50} height={50} />
          </div>
        )}
      </div>
    </div>
  );
};

export default StoresCheckoutLog;
