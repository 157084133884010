export default {
  auth: {
    text_welcome: "Welcome back! Please login to your account",
    text_login: "Login",
    text_save_password: "Remember me",
    text_forgot_password: "Forgot password?",
    text_forgot_password_guideline:
      "Enter your email and we will send you a guidelines to reset your password",
    text_email_required: "Email field is required",
    text_back_to_login: "Back to Login",
    text_send_new_link: "Request a new link",
    text_reset_password: "Set password",
    text_send_reset_instruction: "Send Reset Instructions",
    text_markup_mail: "example@hotmail.com",
    text_password_placeholder: "Type your password",
    text_reset_password_title: "Set your password",
    text_new_password: "New password",
    text_confirm_password: "Confirm password",
    text_all_fields_required: "All fields are required",
    text_password_length_error: "Password must have at least 5 character",
    text_password_match_error: "Password must match",
    text_warning: "Warning",
    text_password_changed: "Password is changed successfully",
  },
  admin: {
    regions: {
      text_regions: "Regions",
      text_subregions: "Subregions",
      text_region_notifications: "Notifications",
      text_region_postal_codes: "Postal Codes",
      text_region_zip_codes: "Zip Codes",
      text_region_add_zip_code: "Add Zip Code",
      text_region_add_postal_code: "Add Postal Code",
      text_list_of_regions: "List of regions",
      text_list_of_subregions: "List of subregions",
      text_web_app_link: "Web App",
      text_th_name: "Name",
      text_th_region: "Region",
      text_th_description: "Description",
      text_th_currency: "Currency",
      text_th_return_days: "Return Days",
      text_th_return_days_premium: "Return Days-Premium Plan",
      text_th_return_days_free: "Return Days-Free Plan",
      text_create_new: "Create new region",
      text_create_new_subregion: "Create new subregion",
      text_edit: "Edit selected region",
      text_edit_subregion: "Edit selected subregion",
      text_input_name: "Region name",
      text_input_name_subregion: "Subegion name",
      text_input_short_name: "Short name",
      text_input_description: "Region description",
      text_input_description_subregion: "Subregion description",
      text_input_days_to_return_premium: "Return Period - Premium Plan",
      text_input_days_to_return: "Return Period - Basic Plan",
      text_input_days_to_return_subregion: "Return Period",
      text_input_nubpn_sms_link: "NUbPN SMS Link",
      text_input_default_price: "Default Container Price",
      text_input_tax_rate: "Tax rate (PST)",
      text_select_currency: "Select Currency",
      text_select_charging: "Select Charging Option",
      text_all_fields_required: "*All fields are required",
      text_modal_delete:
        "Are you sure that you want to delete the selected region?",
      text_no_regions: "There are no regions",
      text_no_subregions: "There are no subregions",
      text_charging: "Charging options",
      text_no_postal_codes: "There are no postal codes",
      text_no_zip_codes: "There are no zip codes",

      text_country: "Country",
    },
    admins: {
      text_admins: "Admins",
      text_list_of_admins: "List of Admins",
      text_th_email: "Email",
      text_th_name: "Full Name",
      text_th_assigned_region: "Assigned Region",
      text_no_admins: "There are no admins",
      text_edit: "Edit selected admin",
      text_create: "Create new admin",
      text_input_email: "Email",
      text_input_name: "Full name",
      text_input_region_selection: "Region selection",
      text_input_region_placeholder: "Assign region",
      text_input_address: "Address",
      text_checkout_type: "Checkout Type",
      text_default_langiage: "Default Language",
      text_input_email_placeholder:
        "Enter the email of a user you want to invite to be admin",
      text_successfully_edited: "Successfully Edited Admin",
      text_successfully_sent: "Invite successfully sent to",
      text_successfully_deleted: "Successfully deleted admin",
      text_modal_delete:
        "Are you sure that you want to delete the selected admin?",
    },
    notifications: {
      text_notifications: "Notifications",
      text_list_of_notifications: "List of Notifications",
      text_no_notifications: "There are no notifications",
      text_select_guideline: "Select one to see details",
      text_edit: "Edit selected notifications",
      text_description: "Notifications Description",
    },
  },
  button: {
    text_cancel: "Cancel",
    text_create: "Create",
    text_save: "Save",
    text_delete: "Delete",
    text_edit: "Edit",
    text_retire: "Retire",
    text_return: "Return",
    text_add_location: "Add Location",
    text_edit_location: "Edit Location",
    text_add_restaurant: "Add Store",
    text_add_group: "Add Group",
    text_edit_group: "Edit Group",

    text_add_ordering: "Add Ordering Channel",
    text_add_tag: "Create Tag",
    text_edit_restaurant: "Edit Store",
    text_add_cleaner: "Add Cleaner",
    text_create_form: "Create New Form",
    text_create_transfer: "Create New Transfer",
    text_view: "View",
    text_monthly_summary: "Monthly Summary",
    text_settings: "Settings",
    text_account_summary: "Account Summary",
    text_statistic: "Statistic",
    text_generate_data: "Generate",
    text_back: "Back",
    text_update: "Update",
    text_add: "Add",
    text_add_container_type: "Add Container Type",
    text_start_scanning: "Start Scanning",
    text_scan: "Scan",
    text_scanning_progress: "Scanning in progress",
    text_submit: "Submit",
    text_search: "Search",
    text_filter: "Filter",
    text_resolve: "Resolve",
    text_charge: "Charge",
    text_show_details: "Show Details",
    text_delete_replenishment: "Delete Replenishment",
    text_charge_and_retire: "Charge & Retire",
    text_send_via_email: "Send via Email",
    text_send: "Send",
  },
  toast: {
    text_successfully_submited: "Successfully submitted!",
    text_successfully_added: "Successfully Added!",
  },
  currency: {
    text_usd: "USD$",
    text_can: "CAN$",
    text_currency: "Currency",
  },
  common: {
    text_not_available: "N/A",
    text_all_fields_required: "*Required fields",
    text_toast_all_fields_required:
      "Please complete all required fields to save",
    text_all_fields_are_required: "All fields are required",
    text_something_went_wrong: "Something went wrong",
    text_reusables: "Reusables",
    text_generate_qr_codes: "Generating QR codes",
    text_download_qr_code: "Download QR Code",
    text_profile_info: "Profile Information",
    text_container_info: "Container Information",
    text_container_details: "Container Details",
    text_state: "State",
    text_user: "User",
    text_located_at: "Located at",
    text_visible: "Visible",
    days: {
      monday: "Mon",
      tuesday: "Tue",
      wednesday: "Wed",
      thursday: "Thu",
      friday: "Fri",
      saturday: "Sat",
      sunday: "Sun",
    },
    input: {
      text_input_full_name: "Full name",
      text_input_address: "Address",
      text_input_pickup_address: "Pickup Address",
      text_select_region: "Select Region",
      text_input_email_address: "Email Address",
      text_input_phone_number: "Phone Number",
      text_markup_phone: "+1(222)222-222",
      text_fullName_placeholder: "Surname & Name",
    },
    text_th_name: "Name",
    text_th_status: "Status",
    text_th_charged: "Charged",
    text_th_charges_and_tax: "Total Charges (Tax included)",
    text_details: "Details",
    text_th_service: "Service",
    text_th_refund: "Refund",
    text_th_refunds: "Refunds",
    text_th_charges: "Charges",
    text_th_fees: "Fees",
    text_th_commission: "Commission",
    text_th_tax_fees: "Tax Fees",
    text_th_stripe_fees: "Stripe Fees",
    text_th_amount: "Amount",
    text_th_created: "Created",
    text_signature: "Signature",
    text_transaction: "Transaction",
    text_date: "Date",
    text_date_created: "Date Created",
    text_total: "Total",
    text_no_transactions: "No transactions found",
    text_borrowed: "Borrowed",
    text_returned: "Returned",
    text_purchased: "Purchased",
    text_ordered_from: "Ordered from",
    text_returned_to: "Returned to",
    text_transaction_history: "Transaction History",
    text_transaction_type: "Transaction Type",
  },
  containerManagement: {
    add: {
      text_add_containers: "Add Containers",
      text_search_batch_placeholder: "Search Batch by Name or ID",
      text_search_container_placeholder: "Search Container by ID",
      text_select_placeholder: "Chose Container Type",
      text_retire_batch: "Retire Batch",
      text_filter: "Filter",
      text_th_batch_name: "Batch Name",
      text_th_created: "Created",
      text_th_amount: "Amount",
      text_th_containerId: "Container ID",
      text_th_uniqueID: "Unique Container ID",
      text_th_uniqueuhf_ID: "Unique Container UHF ID",
      text_th_qrCode: "QR Code",
      text_download: "Download Container Data",
      text_no_batches: "There are no batches",
      text_no_containers: "There are no containers",
      text_container_list: "Container List",
      text_select_guideline: "Select Batch to see Containers details",
      text_successfully_deleted_batch: "Successfully Deleted Container Batch!",
      text_successfully_deleted_containers:
        "Successfully Deleted Selected Containers",
      text_successfully_deleted_container:
        "Successfully Deleted Selected Container",
      text_modal_delete_batch:
        "Are you sure that you want to delete the selected container batch?",
      text_modal_delete_containers:
        "Are you sure that you want to delete the selected containers?",
      text_modal_delete_container:
        "Are you sure that you want to delete the selected container?",
      text_successfully_created_batch: "Successfully Created New Batch!",
      text_redirect_url: "Redirect URL",
    },
    create: {
      text_input_name: "Containers Name",
      text_input_name_placeholder: "Insert Name of Container",
      text_input_material: "Container Material",
      text_input_material_placeholder: "e.g. Stainless Steel",
      text_input_size: "Container Size",
      text_input_height: "Height",
      text_input_width: "Width",
      text_input_length: "Length",
      text_input_size_placeholder: "e.g. 30x20x30",
      text_input_volume: "Container Volume",
      text_input_volume_placeholder: "e.g. 30",
      text_default_price: "Set Default Price",
      text_select_currency: "Change Currency",
      text_input_price_per_use: "Price Per Use",
      text_input_price_per_use_store: "Price for Stores",
      text_input_replenishment_price: "Replenishment price",
      text_input_late_fee: "Daily Late Fee",
      text_input_price: "Container Price",
      text_th_name: "Container Name",
      text_th_size: "Size",
      text_th_material: "Material",
      text_new_container: "New container",
      text_edit_container: "Edit container",
      text_created_container: "Created Containers",
      text_successfully_deleted_types:
        "Successfully Deleted Selected Container Types!",
      text_successfully_deleted_type:
        "Successfully Deleted Selected Container Type!",
      text_successfully_edited_type: "Successfully Edited Container Type!",
      text_modal_delete_type:
        "Are you sure that you want to delete the selected container type?",
      text_successfully_created_type:
        "Successfully Created New Container Type!",
      text_successfully_edited_type: "Successfully Edited Container Type!",
    },
  },
  dropoff: {
    text_dropoff_locations: "Dropoff Locations",
    text_dropoff_location: "Dropoff Location",
    text_dropoff_locations_list: "List of Dropoff Locations",
    text_search_locations: "Search Locations",
    text_no_locations: "There are no locations",
    text_th_name: "Location Name",
    text_th_description: "Description",
    text_th_restaurant: "Store",
    text_closed: "Closed",
    text_input_name: "Location Name",
    text_input_address: "Address",
    text_input_description: "Location Description",
    text_select_region: "Select Region",
    text_select_cleaning_option: "Select cleaning option",
    text_working_hours: "Working Hours",
    text_cleaning_settings: "Cleaning Settings",
    text_cleaning_service: "Using Cleaning Service",
    text_cleaning_by_themselves: "Own Distribution",
    text_select_guideline: "Select a dropoff location to see details.",
    text_modal_delete_location:
      "Are you sure that you want to delete the selected dropoff location?",
    text_successfully_deleted_location: "Successfully Deleted Dropoff Location",
    text_is_visible: "Is this location visible to members?",
    text_number_of_containers: "Number of Containers",
  },
  restaurant: {
    text_restaurats: "Stores",
    text_restaurat: "Store",
    text_groups: "Groups",
    text_archived_stores: "Inactive Stores",
    text_list_groups: "List of Groups",
    text_list_of_restaurants: "List of Stores",
    text_search_restaurants: "Search Store by Name, Owner or Email",
    text_search_groups: "Search Group by Name",
    text_th_name: "Store Name",
    text_th_group_name: "Group Name",
    text_th_amount: "Total amount of containers",
    text_th_total_stores: "Number of stores",
    text_th_number_of_containers: "Number of containers",
    text_th_unique_container_id: "Container ID",
    text_th_status: "Status",
    text_th_container_type: "Container type",
    text_th_logs_of_activity: "Logs of Activity",
    text_th_activity: "Activity",
    text_th_containers: "Containers",
    text_th_replenishment: "Replenishment",
    text_th_price: "Price",
    text_th_charged: "Charged",
    text_details: "Details",
    text_th_type: "Type",
    text_th_delivery: "Last delivery",
    text_th_used_since: "Used since",
    text_th_used_by_store: "Used by Store",
    text_th_returned_since: "Returned since",
    text_th_available: "Available",
    text_th_current_status: "Current Status",
    text_no_restaurants: "There are no stores",
    text_no_groups: "There are no groups",
    text_no_archived_stores: "There are no inactive stores",
    text_no_invoices: "This store has no invoices yet",
    text_no_transactions: "No transactions found",
    text_input_owner: "Owner",
    text_tender_number: "Tender Number",
    text_terminal_id: "Terminal ID",
    text_institution_route_ID: "Institution Route ID",
    text_input_owner_placeholder: "Surname & Name",
    text_input_name: "Store Name",
    text_input_website: "Website",
    text_input_password: "Password",
    text_input_secureCode: "Secure Code",
    text_is_dropoff: "Is this store a dropoff location?",
    text_is_visible: "Is this store visible to members?",
    text_select_guideline: "Select a store to see store details.",
    text_select_guideline_group: "Select a group to see group details.",
    text_sent_automaticly: "Sent automatically",
    text_charged_automaticly: "Charged",
    text_successfully_deleted: "Successfully Deleted Store!",
    text_successfully_archived: "Successfully Deactivated Store!",
    text_successfully_unarchived: "Successfully Reactivated Store!",
    text_successfully_deleted_group: "Successfully Deleted Group!",
    text_successfully_edited: "Successfully Edited Store!",
    text_modal_delete_restaurant:
      "Are you sure that you want to delete the selected store?",
    text_modal_archive_restaurant:
      "Are you sure that you want to deactivate the selected store?",
    text_modal_unarchive_restaurant:
      "Are you sure that you want to reactivate the selected store?",
    text_modal_delete_group:
      "Are you sure that you want to delete the selected group?",
    text_successfully_sent: "Invite Successfully sent to",
    text_successfully_edited_par_level: "Successfully Edited Par Level!",
    text_successfully_edited_used_containers:
      "Successfully Edited Containers Used by Store!",
    text_successfully_edited_number:
      "Successfully Edited Available number for type",
    text_containers_settings: "Container settings",
    text_pricing_management: "Pricing management",
    text_pricing_title: "Set price for container type",
    text_monthly_pricing_title: "Set monthly price",
    text_select_type: "Choose Container Type",
    text_par_level: "Par Level",
    text_available_containers: "Available Containers",
    text_amount: "Amount",
    text_par_level_na: "Set Par Level to N/A",
    text_successfully_edited_price: "Successfully edited price",
    text_download_data: "Download Store Data",
    text_download_statistics: "Download Statistics",
    text_stores_checkout_feed: "Activity Feed",
    text_successfully_added_channel: "Successfully added ordering channel.",
    text_successfully_edited_channel: "Successfully edited ordering channel.",
    text_successfully_deleted_channel: "Successfully deleted ordering channel ",
    text_modal_delete_channel:
      "Are you sure that you want to delete the selected ordering channel?",
    text_modal_delete_tag:
      "Are you sure that you want to delete the selected tag?",
    text_url_example: "https://example.com",
  },
  user: {
    text_user: "User",
    text_list_of_members: "List of Members",
    text_list_of_unregistered: "List of Unregistered Users",
    text_reusable_members: "Reusables Members",
    text_unregistered_users: "Unregistered Users",
    text_search_member_placeholder: "Search User by Name,Phone or Reuser Id",
    text_search_user_placeholder: "Search User by Phone number",
    text_search_user_name_email: "Search User by Name or Email",
    text_members: "Members",
    text_member: "Member",
    text_unregistered: "Unregistered",
    text_th_data: "Data",
    text_th_membership: "Membership",
    text_th_in_possession: "In Possession",
    text_th_dueDate: "Due Date",
    text_overdue: "Overdue",
    text_container_in_possession: "Containers in Possession",
    text_containers_used: "Total Containers Used",
    text_average_holding_time: "Average Holding Time (Days)",
    text_most_used_store: "Most Used Store",
    text_no_users: "There are no users",
    text_no_reuser_cards: "There are no reuser cards",
    text_no_registered: "There are no registered members",
    text_successfully_edited: "Successfully Edited User!",
    text_successfully_deleted: "Successfully Deleted User!",
    text_modal_delete: "Are you sure that you want to delete selected user?",
    text_payment_history: "Payment History",
    text_container_details: "View Container Details",
    text_select_guideline: "Select User to view their details",
    text_select_guideline_reuser_card: "Select Reuser Card to view details",
    text_successfully_returned: "Successfully Returned Containers!",
    text_select_restaurant: "Select Dropoff Location",
    text_date_overdue: "Return date is overdue",
    text_no_return_date: "Return date not specified",
    text_download_data: "Download Member Data",
    text_registration_statistics: "Registration Statistics",
  },
  cleaner: {
    text_cleaners: "Cleaners",
    text_cleaner: "Cleaner",
    text_list_of_cleaners: "List of Cleaners",
    text_search_cleaner: "Search Cleaner",
    text_no_cleaners: "There are no cleaners",
    text_th_name: "Cleaner Name",
    text_th_amount: "Total amount of containers",
    text_successfully_deleted: "Successfully Deleted Cleaner!",
    text_modal_delete: "Are you sure that you want to delete selected cleaner?",
    text_select_guideline: "Select a Cleaner to see cleaner details.",
    text_successfully_edited: "Successfully Edited Cleaner!",
  },
  transactions: {
    text_financial_info: "Financial info dashboard",
    text_financial_statistics: "Financial Statistics",
    text_charged_manually: "Charged manually",
    text_charge_stripe: "Charge via Stripe",
    text_successfully_saved: "Successfully updated invoice details.",
    text_successfully_charged: "Successfully charged invoice.",
    text_successfully_payout: "Payout Successfully Finished.",
  },
  distribution: {
    text_replenishment: "Replenishment",
    text_replenishment_title: "Create Replenishment Form",
    text_successfully_added: "Successfully Added Replenishment",
    text_successfully_deleted: "Successfully Deleted Replenishment",
    text_find_restaurant: "Find Store",
    text_search_restaurant: "Type Store Name",
    text_validate_amount: "Validate containers amount*",
    text_history: "Replenishment History",
    text_no_replenishment: "There are no replenishments",
    text_search_restaurants: "Search Store",
    text_deadline: "Deadline",
    text_cancel_delivery_modal:
      "Are you sure that you want to delete selected replenishment?",
    text_successfully_sent_email: "Successfully sent to ",
    text_email_required: "Email address is required.",
    text_send_replenishment: "Send Replenishment via Email",
    text_send_replenishments: "Send Replenishments via Email",
    templates: "Templates",
  },
  transfer: {
    text_transfer: "Transfer",
    text_successfully_transferred: "Successfully transferred",
    text_from_store: "From Store",
    text_to_store: "To Store",
    text_choose_restaurant: "Choose Store",
    text_choose_containers: "Choose Container Types",
    text_history: "Transfer History",
    text_no_transfers: "There are no transfers",
    text_store_name: "Store name...",
    text_select_different:
      "Please select different store to transfer the containers to.",
    text_scanning_containers: "Scan Container Types",
    text_cancel_transfer_modal:
      "Are you sure that you want to cancel and delete selected transfer?",
    text_successfully_canceled: "Successfully Canceled Transfer",
  },
  cleaning: {
    text_scanned_batch_list: "Scanned Batch List",
    text_search_batch: "Search Batch",
    text_no_chart_data: "No chart data",
    text_cleaned_containers: "Cleaned Containers",
    text_clean_containers: "Currently clean containers at facility",
    text_can_not_scan: "You can't scan in this browser",
    text_successfully_scanned: "Successfully scanned QR Code!",
    text_invalid_code: "Invalid QR code!",
    text_successfully_removed: "Successfully removed container",
    text_empty_list: "List of scanned containers can't be empty",
    text_successfully_edited_batch: "Successfully Edited Batch!",
    text_scanning_containers: "Scanning Containers",
    text_scan_date: "Scan date",
    text_number_of_scanned: "Number of scanned containers",
    text_remove_from_list: "Remove from the list",
    text_no_scanned: "There are no scanned containers",
    text_waiting: "Waiting to scan QR Code",
  },
  notifications: {
    text_notifications: "Notifications",
    selectOptions: {
      text_all: "All",
      text_inventory_inaccuracy: "Inventory Inaccuracy",
      text_request_more: "Need More Containers",
      text_containers_missing: "Containers Missing",
      text_transaction_inaccuracy: "Transaction Inaccuracy",
      text_other: "Other",
    },
    text_no_notifications: "There are no notifications",
  },
  refund: {
    text_search_restaurant: "Search by name or owner",
    text_search_user: "Search by name or email",
    text_create: "Create Refund",
    text_transaction_amount: "Transaction amount",
    text_refund_amount: "Refund amount",
    text_max_amount: "Max amount for refund is",
    text_warning: "Refund for this transaction has already been made!",
    text_successfully_refunded: "Successfully refunded!",
    text_previous_refunds: "Previously Refunded",
  },
  report: {
    text_user: "User",
    text_deleted: "Deleted User",
    text_container: "Container",
    text_uniqueId: "Unique Id",
    text_location: "Location",
    text_returned: "Returned",
    text_dueDate: "Due Date",
    text_overdue: "Overdue",
    text_on_time: "On time",
    text_refund_period: "Refund Period Expired",
    text_at_location_title: "Containers at locations,",
    text_at_location_subtitle: "awaiting confirmation from cleaners",
    text_overdue_title: "Overdue Containers,",
    text_overdue_subtitle: "confirmed by cleaners",
    text_search_container: "Search by Container name",
    text_no_reports: "There are no reports",
    text_resolve_report: "Resolve report",
    text_charge: "Charge penalties",
    text_charge_container: "Charge Container",
    text_successfully_deleted: "Successfully Deleted Report!",
    text_no_overdue_reports: "There are no overdue reports",
    text_modal_delete: "Do you want to delete this report?",
    text_modal_delete_no_charge:
      "Do you want to delete this report and not charge this user any penalties?",
    text_modal_delete_no_refund:
      "Do you want to delete this report without refunding the amount charged to the user?",
    text_charge_and_retire: "Charge & Retire Container",
  },
  dashboard: {
    text_containers_at_restaurants: "Containers at Stores",
    text_restaurats: "Stores",
    text_members: "Reusables Members",
    text_in_use: "Containers in Use",
    text_clean_containers: "Containers at Distribution",
    text_containers_at_locationss: "Containers at Locations",
  },
  sidebar: {
    text_dashboard: "Dashboard",
    text_admin: "Admin",
    text_container: "Container management",
    text_dropoff: "Dropoff management",
    text_restaurat: "Store management",
    text_member: "Member management",
    text_cleaner: "Cleaner management",
    text_coupon: "Coupon management",
    text_transactions: "Transactions",
    text_finance: "Finance",
    text_distribution: "Distribution",
    text_container_stats: "Containers and SRB's",
    text_impact: "Impact",
    text_usage: "Usage",
    text_cleaning: "Cleaning",
    text_notifications: "Notifications",
    text_refund: "Refund",
    text_payouts: "Payouts",
    text_report: "Report",
    text_reports: "Reports",
    text_logout: "Logout",
    text_rewards: "Reward management",
    text_permissions: "Permissions",
    text_settings: "Settings",
  },
  pushNotifications: {
    text_send_notification: "Send Notification to ",
    text_title: "Title",
    text_body: "Body",
    text_type: "Type",
    text_successfully_sent: "Successfully sent notifications.",
    text_sendind_failed: "Failed to send to ",
  },
  coupon: {
    text_coupons: "Coupons",
    text_coupon: "Coupon",
    text_list_of_coupons: "List of Coupons",
    text_search_coupons: "Search Coupon",
    text_no_coupons: "There are no coupons",
    text_th_name: "Coupon Name",
    text_th_amount: "Total amount of coupon",
    text_successfully_deleted: "Successfully Deleted Coupon!",
    text_modal_delete: "Are you sure that you want to delete selected coupon?",
    text_select_guideline: "Select a coupon to see coupon details.",
    text_successfully_edited: "Successfully Edited Coupon!",
  },
  rewards: {
    text_rewards: "Rewards",
    text_reward: "Reward",
    text_list_of_rewards: "List of Rewards",
    text_search_rewards: "Search Reward",
    text_no_rewards: "There are no rewards",
    text_th_name: "Reward Name",
    text_successfully_deleted: "Successfully deactivated reward!",
    text_modal_delete:
      "Are you sure that you want to deactivate selected reward?",
    text_select_guideline: "Select a reward to see reward details.",
    text_successfully_edited: "Successfully Edited Reward!",
    text_successfully_saved: "Successfully Saved Reward!",
  },
  storePricinig: {
    price_cleaning_service: 50,
    price_cleaning_themselves: 35,
  },
  reports: {
    reports_and_analytics: "Reports & Analytics",
    user_app_usage_behavior: "User App Usage Behavior",
    self_assigned_containers: "Self-Assigned Containers",
    dynamic_links_stats: "Dynamic Links Statistics",
    subregion_stats: "Subregion Statistics",
  },
  permissions: {
    permissions: "Permissions",
    secure_code_required: "Secure Code Required",
  },
  app_settings: {
    settings: "Settings",
    terms_of_service: "Terms of Service",
    privacy_policy: "Privacy Policy",
    faq: "FAQ",
  },
};
