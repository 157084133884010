import React, { useEffect, useState } from "react";
import styles from "./restaurant.module.scss";
import { mainActions } from "../../../_store/actions/main.actions";
import { useDispatch, useSelector } from "react-redux";
import RentedChart from "../../restaurant/monthlySummary/statistic/rentedChart";
import {
  mapDailyTransactions,
  mapMonthlyTransactions,
  getDropdownListMonths,
  isSameMonthAndYear,
  getDropdownListYears,
} from "../../../_utils/mapTransactions";
import { makeSelectMap } from "../../../_utils/utils";
import DownloadData from "./downloadData";
import Button from "../../button";
import { modalActions } from "../../../_store/actions/modal.actions";
import StoresCheckoutLog from "../header/storeCheckoutLog";
import t from "../../../_config/constants";
import DownloadLogsStatistics from "./downloadLogsStatistics";
const DashboardRestaurant = () => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const [restaurants, setRestaurants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [invoices, setInvoices] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState();
  const [monthsOptions, setMonthsOptions] = useState([]);
  const [restaurantOptions, setRestaurantOptions] = useState([]);
  const [arrayForLineChart, setarrayForLineChart] = useState([]);
  const [thisMonthTransactions, setThisMonthTransactions] = useState([]);
  const [rentedContainers, setRentedContainers] = useState(0);
  const [selectedContainerType, setSelectedContainerType] = useState("");
  const [yearsOptions, setYearsOptions] = useState([]);
  const [selectedYear, setSelectedYear] = useState();
  const [regionId, setRegionId] = useState("");
  const [subregionId, setSubregionId] = useState("");

  const [selectedRestaurant, setSelectedRestaurant] = useState("");
  const [selectedMonthly, setSelectedMonthly] = useState(false);
  const [yearlyInvoices, setYearlyInvoices] = useState([]);
  useEffect(() => {
    const arr = getDropdownListYears();
    setSelectedYear(arr[0].value);
    setYearsOptions(arr);
  }, []);
  useEffect(() => {
    setLoading(true);
    if (restaurants) {
      const getTransactions = async () => {
        let date = new Date();
        let firstDay = new Date(
          date.getFullYear(),
          date.getMonth(),
          1
        ).getTime();
        let today = new Date().getTime();
        let filter = [];
        filter.push({
          restaurant_id: selectedRestaurant
            ? selectedRestaurant
            : { $in: restaurants.map((item) => item._id) },
          createdAt: { $gte: firstDay, $lte: today },
        });
        let response = await dispatch(
          mainActions.run(
            "restaurantPendingTransaction",
            "restaurantTransaction",
            "all",
            { filter }
          )
        );
        setThisMonthTransactions(response);
        setLoading(false);
      };
      getTransactions();
    }
  }, [restaurants, selectedRestaurant]);
  useEffect(() => {
    const regionId = store?.auth?.user?.regionId;
    setRegionId(regionId);
    setSubregionId(store?.auth?.user?.subregionId);
    const getRestaurants = async (id, subregionId) => {
      let filter = [{ archived: { $ne: true } }];
      if (id) filter.push({ regionId: id });
      if (subregionId && subregionId !== "*") filter.push({ subregionId });
      let data = await dispatch(
        mainActions.run("profiles", "restaurant", "all", { filter })
      );
      if (data) {
        setRestaurants(data);
        let arr = makeSelectMap(data);
        let filterOptions = [...arr];
        filterOptions.unshift({ label: "All Stores", value: "" });
        setRestaurantOptions(filterOptions);
      }
    };
    if (store.auth && store.auth.user && regionId && regionId !== "*") {
      getRestaurants(regionId, store.auth.user.subregionId);
    } else {
      getRestaurants();
    }
    setSelectedRestaurant("");
  }, [store.auth && store.auth.user]);
  useEffect(() => {
    let chartData = [];
    if (!selectedMonthly) {
      if (
        !invoices.length &&
        isSameMonthAndYear(selectedMonth, new Date().getTime())
      ) {
        chartData = mapDailyTransactions(
          thisMonthTransactions,
          selectedContainerType
        );
      } else if (invoices.length) {
        let transactions = [];
        invoices.map(
          (invoice) =>
            (transactions = [...transactions, ...invoice.transactionList])
        );
        chartData = mapDailyTransactions(
          transactions,
          selectedContainerType,
          selectedMonth
        );
      } else {
        chartData = mapDailyTransactions(
          [],
          selectedContainerType,
          selectedMonth
        );
      }
    } else {
      chartData = mapMonthlyTransactions(
        yearlyInvoices,
        thisMonthTransactions,
        selectedYear,
        selectedContainerType
      );
    }
    setarrayForLineChart(chartData);
    let total = chartData?.reduce(
      (prevValue, value) => Number(prevValue) + Number(value.value),
      [0]
    );
    setRentedContainers(total);
  }, [selectedContainerType, invoices, yearlyInvoices, selectedMonthly]);
  useEffect(() => {
    setLoading(true);
    if (restaurants) {
      const getInvoices = async () => {
        const firstDay = new Date(Date.UTC(selectedYear, 1)).getTime();
        const lastDay = new Date(Number(selectedYear) + 1, 0, 2).getTime();
        let filter = [];
        filter.push({
          user_id: selectedRestaurant
            ? selectedRestaurant
            : { $in: restaurants.map((item) => item._id) },
          transactionType: "RESTAURANT_INVOICE",
          createdAt: { $gte: firstDay, $lte: lastDay },
        });
        let response = await dispatch(
          mainActions.run("transactions", "transaction", "all", { filter })
        );
        setYearlyInvoices(response);
        setLoading(false);
      };
      getInvoices();
    }
  }, [restaurants, selectedYear, selectedRestaurant]);
  useEffect(() => {
    if (
      !selectedMonthly &&
      selectedMonth &&
      !isSameMonthAndYear(selectedMonth, new Date().getTime())
    ) {
      const getInvoices = async (month) => {
        const firstDay = new Date(
          Date.UTC(month.getFullYear(), month.getMonth() + 1, 1)
        ).getTime();
        const lastDay = new Date(
          Date.UTC(month.getFullYear(), month.getMonth() + 2, 0)
        ).getTime();
        let filter = [];
        filter.push({
          user_id: selectedRestaurant
            ? selectedRestaurant
            : { $in: restaurants?.map((item) => item._id) },
          transactionType: "RESTAURANT_INVOICE",
          createdAt: { $gte: firstDay, $lte: lastDay },
        });
        let response = await dispatch(
          mainActions.run("transactions", "transaction", "all", { filter })
        );
        if (response) setInvoices(response);
        setLoading(false);
      };
      setLoading(true);
      getInvoices(selectedMonth);
    } else {
      setInvoices([]);
    }
  }, [selectedMonth, thisMonthTransactions, selectedRestaurant]);
  useEffect(() => {
    if (selectedYear) {
      const arr = getDropdownListMonths(selectedYear);
      setSelectedMonth(arr[0].value);
      setMonthsOptions(arr);
    }
  }, [selectedYear]);
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h2>Containers Rental</h2>
        <div className={styles.buttons}>
          <DownloadLogsStatistics regionId={regionId} />
          <DownloadData
            disabled={loading}
            thisMonthTransactions={thisMonthTransactions}
            invoices={invoices}
            yearlyInvoices={yearlyInvoices}
            selectedMonthly={selectedMonthly}
            selectedYear={selectedYear}
            regionId={regionId}
            restaurants={restaurants}
            selectedMonth={selectedMonth}
            selectedRestaurant={selectedRestaurant}
          />
          <Button
            disabled={loading}
            label={t.restaurant.text_stores_checkout_feed}
            btnClass="btnWhiteBackground"
            onClick={() =>
              dispatch(
                modalActions.openModal(
                  <StoresCheckoutLog restaurantId={selectedRestaurant} />,
                  "",
                  ""
                )
              )
            }
          />
        </div>
      </div>
      <RentedChart
        loading={loading}
        rentedContainers={rentedContainers}
        selectedMonth={selectedMonth}
        setSelectedContainerType={setSelectedContainerType}
        monthsOptions={monthsOptions}
        arrayForLineChart={arrayForLineChart}
        setSelectedMonth={setSelectedMonth}
        regionId={regionId}
        subregionId={subregionId}
        onDashboard={true}
        restaurantOptions={restaurantOptions}
        setSelectedRestaurant={setSelectedRestaurant}
        selectedMonthly={selectedMonthly}
        setSelectedMonthly={setSelectedMonthly}
        selectedRestaurant={selectedRestaurant}
        selectedYear={selectedYear}
        setSelectedYear={setSelectedYear}
        yearsOptions={yearsOptions}
      />
    </div>
  );
};

export default DashboardRestaurant;
