import React, { useState, useEffect } from "react";
import styles from "./../statistic.module.scss";
import Loading from "../../../../loading";
import Selector from "../../../../selector";
import LineChart from "../../../../charts/line";
import Checkbox from "../../../../checkbox";
import t from "../../../../../_config/constants";
import { useDispatch } from "react-redux";
import { mainActions } from "../../../../../_store/actions/main.actions";
import { makeSelectMap } from "../../../../../_utils/utils";
const RentedChart = React.memo(
  ({
    type = "restaurant",
    loading,
    monthsOptions,
    yearsOptions,
    rentedContainers,
    arrayForLineChart,
    selectedMonth,
    setSelectedMonth,
    selectedYear,
    setSelectedYear,
    setSelectedContainerType,
    setSelectedRestaurant,
    regionId,
    subregionId,
    onDashboard,
    restaurantOptions,
    setSelectedMonthly,
    selectedMonthly,
    selectedRestaurant,
  }) => {
    const dispatch = useDispatch();
    const [containerTypeOptions, setContainerTypeOptions] = useState([]);
    useEffect(() => {
      const fetchAllContainerTypes = async (regionId, subregionId) => {
        let filter = [];
        if (regionId && regionId !== "*") {
          filter.push({ regionId: regionId });
        }
        subregionId && subregionId !== "*" && filter.push({ subregionId });
        let data = await dispatch(
          mainActions.run("data", "containerType", "all", { filter })
        );
        let arr = makeSelectMap(data);
        let filterOptions = [...arr];
        filterOptions.unshift({ label: "All Containers", value: "" });
        setContainerTypeOptions(filterOptions);
      };
      fetchAllContainerTypes(regionId, subregionId);
    }, [regionId, subregionId]);

    const getSelectedValue = (options, value) => {
      const selectedData = [];
      options &&
        options.forEach((obj) => {
          if (obj.value === value) {
            selectedData.push(obj);
          }
        });
      return selectedData;
    };
    return (
      <div className={styles.wrapper}>
        <div className={styles.options}>
          {!onDashboard && <h2>Containers Rental</h2>}
          {onDashboard && (
            <div className={styles.type}>
              <Selector
                label=""
                placeholder="All Stores"
                multiselect={false}
                selectClassname="selectGray"
                options={restaurantOptions}
                disabled={loading}
                handleChangeSelect={(e) => setSelectedRestaurant(e.value)}
                selectedOption={getSelectedValue(
                  restaurantOptions,
                  selectedRestaurant
                )}
              />
            </div>
          )}
          <div className={styles.type}>
            <Selector
              label=""
              placeholder="All Containers"
              multiselect={false}
              selectClassname="selectGray"
              options={containerTypeOptions}
              disabled={loading}
              handleChangeSelect={(e) => setSelectedContainerType(e.value)}
            />
          </div>
          <div className={styles.row}>
            <div className={styles.icon}>
              <div>
                <span>{rentedContainers}</span>
              </div>
              <span>
                {type == "location" ? "Returned" : "Rented"} this{" "}
                {selectedMonthly ? "year" : "month"}
              </span>
            </div>
            <div className={styles.selectDate}>
              <div className={styles.checkboxMonthly}>
                {onDashboard && (
                  <Checkbox
                    customClass="checkbox"
                    label="Monthly"
                    checked={selectedMonthly}
                    onChange={(e) => setSelectedMonthly(e.target.checked)}
                  />
                )}
              </div>
              <div
                className={
                  styles[selectedMonthly ? "selectDisabled" : "selectMonth"]
                }
              >
                <Selector
                  label=""
                  multiselect={false}
                  selectClassname="selectGray"
                  selectedOption={getSelectedValue(
                    monthsOptions,
                    selectedMonth
                  )}
                  handleChangeSelect={(e) => setSelectedMonth(e.value)}
                  options={monthsOptions}
                  disabled={loading || selectedMonthly}
                />
              </div>
              <div className={styles.selectYear}>
                <Selector
                  label=""
                  multiselect={false}
                  selectClassname="selectGray"
                  selectedOption={getSelectedValue(yearsOptions, selectedYear)}
                  handleChangeSelect={(e) => setSelectedYear(e.value)}
                  disabled={loading}
                  options={yearsOptions}
                />
              </div>
            </div>
          </div>
        </div>
        {loading && (
          <div className={styles.loading}>
            {" "}
            <Loading width={50} height={50} />
          </div>
        )}

        {!loading && !arrayForLineChart.length && (
          <div className={styles.noTransactionsMsg}>
            {t.restaurant.text_no_invoices}
          </div>
        )}
        {!loading && arrayForLineChart.length > 0 && (
          <div className={styles.bigChartSection}>
            <LineChart arrayForLineChart={arrayForLineChart} />
          </div>
        )}
      </div>
    );
  }
);
export default RentedChart;
