import React, { useEffect, useState } from "react";
import styles from "./impact.module.scss";
import { useSelector } from "react-redux";
import { getStatistics } from "../../../_services/statistics.service";
import Datepicker from "../../../_components/newDatepicker";

const Impact = () => {
  const store = useSelector((state) => state);
  const [dateFrom, setDateFrom] = useState(new Date(2023, 9, 1));
  const [dateTo, setDateTo] = useState(new Date());

  const [impactData, setImpactData] = useState([]);

  useEffect(() => {
    const getStats = async (dateFrom, dateTo, subregionId) => {
      try {
        const filter = { subregionId: subregionId };
        filter.dateFrom = dateFrom.getTime();
        if (
          dateTo.getFullYear() === new Date().getFullYear() &&
          dateTo.getMonth() === new Date().getMonth() &&
          dateTo.getDate() === new Date().getDate()
        ) {
          filter.dateTo = new Date().getTime();
        } else {
          filter.dateTo = new Date(
            dateTo.getFullYear(),
            dateTo.getMonth(),
            dateTo.getDate()
          ).getTime();
        }
        const response = await getStatistics({
          mode: "get-subregion-impact",
          filter: filter,
        });
        if (response) {
          setImpactData(response);
        }
      } catch (err) {}
    };

    if (dateFrom && dateTo && store?.auth?.user?.subregionId) {
      getStats(dateFrom, dateTo, store.auth.user.subregionId);
    }
  }, [dateFrom, dateTo, store.auth && store.auth.user]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.datepickers}>
        <div className={styles.datepicker}>
          <Datepicker
            label={"From"}
            minDate={new Date(2023, 9, 1).getTime()}
            maxDate={dateTo.getTime() || new Date().getTime()}
            selected={dateFrom}
            onDatePickerChange={(val) => setDateFrom(val)}
            name="dateFrom"
            placeholder="mm/dd/yyyy"
          />
        </div>{" "}
        <div className={styles.datepicker}>
          <Datepicker
            label={"To"}
            minDate={dateFrom.getTime() || new Date(2023, 9, 1).getTime()}
            maxDate={new Date().getTime()}
            selected={dateTo}
            onDatePickerChange={(val) => setDateTo(val)}
            name="dateTo"
            placeholder="mm/dd/yyyy"
          />
        </div>
      </div>
      <div className={styles.container}>
        <p>Number of containers reused: (checkouts + returns)</p>
        <p> {impactData.reusedContainers}</p>
      </div>
      <div className={styles.container}>
        <p>Waste avoided</p>
        <p> {impactData.wasteAvoided} kg</p>
      </div>
      <div className={styles.container}>
        <p>CO2 emissions avoided</p>
        <p>{impactData.emissionsAvoided} kg</p>
      </div>
      <div className={styles.container}>
        <p>Water saved</p>
        <p> {impactData.waterSaved} l</p>
      </div>
      <div className={styles.container}>
        <p>Return Rate</p>
        <p>{impactData.returnRate}%</p>
      </div>
    </div>
  );
};

export default Impact;
