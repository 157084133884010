export const getDropdownListMonths = (selectedYear) => {
  let date = new Date();
  let arr = [];
  let months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  if (selectedYear == 2022 && date.getFullYear() == 2022) {
    for (let i = date.getMonth(); i >= 9; i--) {
      arr.push({
        label: months[i],
        value: new Date(selectedYear, i),
      });
    }
  } else if (selectedYear == 2022 && date.getFullYear() != 2022) {
    for (let i = 11; i >= 9; i--) {
      arr.push({
        label: months[i],
        value: new Date(selectedYear, i),
      });
    }
  } else if (selectedYear != date.getFullYear()) {
    for (let i = 11; i >= 0; i--) {
      arr.push({
        label: months[i],
        value: new Date(selectedYear, i),
      });
    }
  } else {
    for (let i = date.getMonth(); i >= 0; i--) {
      arr.push({
        label: months[i],
        value: new Date(selectedYear, i),
      });
    }
  }
  return arr;
};
export const getDropdownListYears = (minYear = 2022) => {
  let date = new Date();
  let arr = [];

  for (let i = date.getFullYear(); i >= minYear; i--) {
    arr.push({
      label: i,
      value: i,
    });
  }

  return arr;
};
